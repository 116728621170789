.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 927px;
}

.text {
  margin-top: 32px;
  color: var(--default-opaque-neutrals-gray-80, #4A4A4A);
  text-align: center;
  font-size: 24px;
  font-weight: 300;
  line-height: 130%;
  text-align: center;
}

.book {
  color: var(--default-opaque-neutrals-gray-80, #4A4A4A);
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  line-height: 150%;
  text-align: center;

  a {
    color: inherit;
  }
}

.author {
  margin-top: 24px;
  color: var(--visual-visual-50, #783BFF);
}