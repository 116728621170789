.card {
  width: 288px;
  min-height: 269px;
  padding: 32px 18px;

  b {
    width: 212px;
  }

  pre {
    margin-top: 64px;
  }
}

@media screen and (max-width: 527px) {
  .subtitle {
    display: none;
  }

  .title {
    font-size: 46px;
  }
}

.space {
  background-color: #cbd5e0;
}